<template>
  <div class="lms-input">
    <b-form-group
      :id="id"
      :label-for="id"
      :label-class="{
        focus: isFocused || value,
        hidden: scrollTop > 10,
      }"
    >
      <template #label
        ><span @click="handleInput">{{ label }}</span></template
      >
      <b-form-input
        v-if="type === 'text'"
        :id="id"
        :ref="id"
        v-model="inputValue"
        :type="type"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="handleBlur"
        @focus="isFocused = true"
        @change="(v) => $emit('change', v)"
        @keyup.enter="(v) => $emit('enter', v)"
        :autocomplete="autocomplete"
      ></b-form-input>
      <b-form-textarea
        :ref="id"
        v-if="type === 'textarea'"
        :id="id"
        v-model="inputValue"
        :placeholder="placeholder"
        rows="6"
        max-rows="6"
        @blur="handleBlur"
        @focus="isFocused = true"
        class="scroll"
        no-resize
        v-on:scroll.native="onScroll"
      ></b-form-textarea>
      <b-form-input
        :ref="id"
        v-if="type === 'number'"
        :type="type"
        :id="id"
        v-model.number="inputValue"
        min="0"
        :placeholder="placeholder"
        @blur="handleBlur"
        @focus="isFocused = true"
        @keypress="isNumber($event)"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'LmsInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFocused: false,
    scrollTop: 0,
  }),
  computed: {
    inputValue: {
      get() {
        if (this.value === 0) {
          return '';
        }
        return this.value;
      },
      set(newValue) {
        this.$emit('update:value', newValue);
      },
    },
  },
  methods: {
    handleInput() {
      if (!this.isFocused) {
        this.isFocused = true;
        this.$nextTick(() => {
          this.$refs[this.id].focus();
        });
      }
    },
    handleBlur(e) {
      this.isFocused = false;
      this.$emit('change', e.target.value);
    },
    onScroll(e) {
      this.scrollTop = e.target.scrollTop;
    },
    positiveNumber(value) {
      return Math.abs(value.replace(/[^0-9]/g, ''));
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
  },
};
</script>
